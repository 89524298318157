import React from 'react'

function Modal({ handleCloseModal, handleButtonAction, btnActionText, btnCloseText, icon, title, text, iconCSSClass, titleCSSClass, textCSSClass }) {
  return (
    <div className="user-area-modal">
      <div className="modal-content">
        <div className={iconCSSClass}>
          <img src={icon} alt="Icon" />
        </div>
        <span className="close-btn" onClick={handleCloseModal}>&times;</span>
        <span className={titleCSSClass}>{title}</span>
        <span className={textCSSClass}>{text}</span>
        <section className="modal-buttons">
          {btnActionText && <button className="delete-btn" onClick={() => handleButtonAction()}>{btnActionText}</button>}
          {btnCloseText && <button className="cancel-btn" onClick={handleCloseModal}>{btnCloseText}</button>}
        </section>
      </div>
    </div>
  )
}

export default Modal