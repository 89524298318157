import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import { Avatar, Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';

import { handleGetActiveUser } from '../redux/actions/user'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import ErrorBoundary from './ErrorBoundary'
import { subscribeToTopic, onMessageListener, checkNotification } from '../utils/notificationsFirebase'
import IconSelector from './IconSelector';

function Dashboard() {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.uiActions.theme)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [cookies] = useCookies();

  const onOpenSidebar = () => {
    setOpenSidebar(!openSidebar)
  }

  const setNotification = (message) => {
    if (message.hasOwnProperty('check')) {
      checkNotification(cookies.tenant.id, cookies.user.access_token)
    } else {
      toast(({ closeToast }) =>
        <div className='c-notification'>
          <div className='c-notification-title'>
            <Avatar className='c-notification-icon'>
              <IconSelector svg='Notification' classname='icon--notification' />
            </Avatar>
            <span className='notification-title'>{message.notification.title}</span>
            <Button className='btn-modal' onClick={closeToast}><IconSelector svg={'CloseLine'} classname={'icon--close'} /></Button>
          </div>
          <p className='notification-message'>{message.notification.body}</p>
        </div>,
        {
          position: "bottom-left",
          autoClose: false,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      )
    }
  }

  onMessageListener(setNotification)

  useEffect(() => {
    dispatch(handleGetActiveUser());
  }, [])

  useEffect(() => {
    subscribeToTopic()
  }, [])

  return (
    <ErrorBoundary>
      <div className={`c-dashboard theme--${theme}`}>
        <Header
          params={{
            sidebarStatus: openSidebar,
          }}
        />
        <Sidebar
          params={{
            sidebarStatus: openSidebar,
            sidebarControl: onOpenSidebar,
          }}
        />
        <div className={openSidebar ? 'c-dashboard-main-content c-dashboard-main-content--open' : 'c-dashboard-main-content c-dashboard-main-content--closed'}>
          <Outlet />
          <Footer />
        </div>
        <ToastContainer limit={4} />
      </div>
    </ErrorBoundary>
  )
}

export default Dashboard
