import { useState, useMemo, useEffect, useRef, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { getTableItems, changeTableItemStatus } from "../utils/dataAPI";
import {
  customStylesLightTheme,
  customStylesDarkTheme,
} from "../data/table-styles";
import { event } from "../utils/googleAnalytics";
import { exportCSV } from "../utils/exportFunctions";
import TableHeader from "./TableHeader";
import IconSelector from "./IconSelector";
import Modal from "./Modal";

function CustomTable({ params }) {
  const { t } = useTranslation();
  const {
    tableType,
    configTable,
    buildRequestParams,
    redirectEditOnClick,
    setIsLoading,
  } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const theme = useSelector((state) => state.uiActions.theme);
  const userPermissions = useSelector((state) => state.user.data.permissions);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState(() =>
    configTable.columns.map((column) => "")
  );
  const [tableParams, setTableParams] = useState({
    page: 1,
    perPage: 10,
    sortBy: configTable.initialSort,
    order: configTable.initialOrder,
  });
  const [hideColumn, setHideColumn] = useState(
    configTable.columns.map((column) =>
      configTable.hiddenColumns.includes(column.id) ? true : false
    )
  );
  const [updatedSearch, setUpdatedSearch] = useState(0);
  const [reloadTable, setReloadTable] = useState(0);
  const [tableError, setTableError] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [action, setAction] = useState(null);
  const [confirmAction, setConfirmAction] = useState(false);
  const [resultAction, setResultAction] = useState(null);
  const [exportResult, setExportResult] = useState(false);
  const previousController = useRef(null);
  const requestAborted = useRef(false);
  const shouldFetchData = useSelector(
    (state) => state.tableReducer.shouldFetchData
  );
  const [exportOptions, setExportOptions] = useState([
    { label: "Excel", value: "" },
    { label: "CSV", value: "" },
  ]);
  const [noDataTitle, setNoDataTitle] = useState("title");

  //Assigning the state hideColumn to each 'omit' key from each column object//
  let initialColumns = [...configTable.columns];

  initialColumns.forEach((column, index) => {
    const linkedIndex =
      column.linkedOmit !== null
        ? initialColumns.findIndex(
            (element) => element.id === column.linkedOmit
          )
        : index;
    column.omit = hideColumn[linkedIndex];
  });

  const columns = useMemo(() => [...initialColumns], [hideColumn]);

  //Function to get all the arguments to be sent on a table request (get, edit, delete, search)//
  const getRequestParams = (data) => {
    let search = [];
    searchText.forEach((text, index) => {
      if (text !== "") {
        search.push({
          field: configTable.columns[index].id,
          value: text,
        });
      }
    });

    const requestParams = buildRequestParams(
      data,
      search,
      {
        ...tableParams,
        page: data.page,
        perPage: data.perPage,
        sortBy: data.sortBy,
        order: data.order,
      },

      searchText.every((value) => value === "") ? [] : search
    );
    return requestParams;
  };

  //Change flag to show there was change on the search input//
  const searchUpdateFlag = () => {
    setUpdatedSearch(updatedSearch + 1);
  };

  //Change flag to show there was a request to reload the table//
  const handleReloadTable = () => {
    setReloadTable(reloadTable + 1);
  };

  //Go to edit page for the table item when it is clicked//
  const onClickRow = (row, redirectEditOnClick = true) => {
    if (userPermissions[configTable.permission].update) {
      // Google Analytics info

      event(
        `open_edit_${tableType}_item`,
        `open_edit_table_item`,
        `open_${tableType}_item`
      );

      if (tableType === "forecasts") {
        return navigate(`../forecasts/details/${row.id}`);
      }

      if (redirectEditOnClick) {
        navigate(`edit/${row.id}`);
      }
    }
  };

  //Save the values of the selected rows for use//
  const handleSelectedRows = (allSelected) => {
    setSelectedRows(allSelected.selectedRows);
  };

  //Executes correspondent function from the multiaction button//
  const onConfirmAction = async () => {
    setLoading(true);
    closeActionModal();
    let selectedIDs = [];

    selectedRows.forEach((row) => {
      selectedIDs.push(row.id);
    });

    let actionResponse = null;

    switch (action) {
      case "activate":
        actionResponse = await changeTableItemStatus(
          {
            requestUrl: configTable.requestUrls.updateStatus,
            body: { activate: true, users_list: selectedIDs },
          },
          dispatch
        );
        break;

      case "deactivate":
        actionResponse = await changeTableItemStatus(
          {
            requestUrl: configTable.requestUrls.updateStatus,
            body: { activate: false, users_list: selectedIDs },
          },
          dispatch
        );
        break;

      default:
        actionResponse = null;
    }

    if (actionResponse && actionResponse.success) {
      if (actionResponse.message === "Usuários ativados com sucesso.") {
        setResultAction(t("headerTableBtn.modal.userActivated"));
      } else {
        setResultAction(t("headerTableBtn.modal.userDeactivated"));
      }
      openActionModal();

      let data = {
        ...tableParams,
        requestType: "get",
      };

      const response = await getTableItems(getRequestParams(data), dispatch);

      if (response && response.success) {
        setTotalRows(response.page_info.total);
        setTableData(response.data);
        setClearRows(!clearRows);
        setSelectedRows([]);
        setTableError(false);
      } else {
        setTableError(true);
        setTableData([]);
      }
    } else {
      setResultAction(actionResponse.message);
      openActionModal();
    }

    setLoading(false);
  };

  const openActionModal = () => {
    setConfirmAction(true);
  };

  const closeActionModal = () => {
    setResultAction(null);
    setConfirmAction(false);
  };

  const onExportTable = async () => {
    //google analytics info//
    event(
      `export_${tableType}_table_csv`,
      `export_table`,
      `${tableType}_export`
    );

    const pageParams = {
      ...tableParams,
      requestType: "get",
      page: 1,
      perPage: totalRows,
    };

    try {
      const response = await getTableItems(
        getRequestParams(pageParams),
        dispatch
      );

      response && response.success
        ? exportCSV(response.data, columns, t(`${tableType}.pageTitle.table`))
        : openExportResult();
    } catch (error) {
      openExportResult();
    }
  };

  const openExportResult = () => {
    setExportResult(true);
  };

  const closeExportResult = () => {
    setExportResult(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);

    const pageParams = {
      ...tableParams,
      requestType: "get",
      order: sortDirection,
      sortBy: column.id,
    };

    try {
      const response = await getTableItems(
        getRequestParams(pageParams),
        dispatch
      );

      if (response && response.success) {
        setTableData(response.data);

        setTableParams({
          ...tableParams,
          order: sortDirection,
          sortBy: column.id,
        });
        setTableError(false);
      } else {
        setTableError(true);
        setTableData([]);
      }

      setLoading(false);
    } catch (error) {
      setTableError(true);
      setTableData([]);
      setLoading(false);
    }
  };

  const handlePageChange = async (page) => {
    setLoading(true);

    const pageParams = {
      ...tableParams,
      requestType: "get",
      page: page,
    };

    try {
      const response = await getTableItems(
        getRequestParams(pageParams),
        dispatch
      );

      if (response && response.success) {
        setTableData(response.data);

        setTableParams({
          ...tableParams,
          page: page,
        });
        setTableError(false);
      } else {
        setTableError(true);
        setTableData([]);
      }

      setLoading(false);
    } catch (error) {
      setTableError(true);
      setTableData([]);
      setLoading(false);
    }
  };

  const handleRowsPerPageChange = async (newPerPage) => {
    setLoading(true);

    const pageParams = {
      ...tableParams,
      requestType: "get",
      perPage: newPerPage,
    };

    try {
      const response = await getTableItems(
        getRequestParams(pageParams),
        dispatch
      );

      if (response && response.success) {
        setTableData(response.data);

        setTableParams({
          ...tableParams,
          perPage: newPerPage,
        });
        setTableError(false);
      } else {
        setTableError(true);
        setTableData([]);
      }

      setLoading(false);
    } catch (error) {
      setTableError(true);
      setTableData([]);
      setLoading(false);
    }
  };

  //Get initial table data//
  const fetchData = async () => {
    try {
      let pageParams = {
        ...tableParams,
        requestType: "get",
      };

      const response = await getTableItems(
        getRequestParams(pageParams),
        dispatch
      );

      if (response && response.success) {
        setTableData(response.data);
        setTableError(false);
      } else {
        setTableError(true);
        setTableData([]);
      }

      setTableParams({
        ...tableParams,
        page: 1,
      });

      setTotalRows(response.page_info.total);
    } catch (error) {
      setTableError(true);
      setTableData([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [user, reloadTable, shouldFetchData]);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(loading);
    }
  }, [loading]);

  //Update table data on each search input change//
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (previousController.current) {
        previousController.current.abort();
        requestAborted.current = true;
      }

      try {
        const controller = new AbortController();
        const signal = controller.signal;

        const pageParams = {
          ...tableParams,
          page: 1,
          requestType: "get",
          signal: signal,
        };

        const response = await getTableItems(
          getRequestParams(pageParams),
          dispatch
        );
        if (response && response.success) {
          setTableData(response.data);

          if (tableType === "rainReport") {
            if (response.status === 204) {
              if (response.code === "last_30_days") {
                setNoDataTitle("last_30_days");
              } else if (response.code === "last_6_months") {
                setNoDataTitle("last_6_months");
              } else {
                setNoDataTitle("title");
              }
            }

            if (response.csv_url && response.xlsx_url) {
              setExportOptions([
                { label: "Excel", value: response.xlsx_url },
                { label: "CSV", value: response.csv_url },
              ]);
            } else {
              setExportOptions([
                { label: "Excel", value: "" },
                { label: "CSV", value: "" },
              ]);
            }
          }

          requestAborted.current = false;
          previousController.current = null;
          setTableError(false);
        } else {
          //condição não testada. atentar para possíveis problemas caso a condição não seja atendida
          if (!requestAborted.current) {
            setTableError(true);
          }

          setTableData([]);
        }

        if (!requestAborted.current) {
          setTableParams({
            ...tableParams,
            page: 1,
          });

          setTotalRows(response.page_info.total);
        }
      } catch (error) {
        setTableError(true);
        setTableData([]);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [updatedSearch]);

    return (
    <div className="table-container">
      <DataTable
        customStyles={
          theme === "light" ? customStylesLightTheme : customStylesDarkTheme
        }
        columns={columns.map((col) => ({
          ...col,
          style: { width: col.width || "auto" },
        }))}
        data={tableData}
        responsive
        progressPending={loading}
        progressComponent={
          <div className="c-table-loading">
            {[...Array(10)].map((element, index) => (
              <Skeleton
                key={index}
                animation="wave"
                variant="rectangular"
                classes={{ root: "skeleton-table-line" }}
              />
            ))}
            <div className="c-skeleton-table-pagination">
              <Skeleton
                animation="wave"
                variant="circle"
                classes={{ root: "skeleton-table-circle-btn" }}
              />
              <Skeleton
                animation="wave"
                variant="circle"
                classes={{ root: "skeleton-table-circle-btn" }}
              />
              <Skeleton
                animation="wave"
                variant="circle"
                classes={{ root: "skeleton-table-circle-btn" }}
              />
              <Skeleton
                animation="wave"
                variant="circle"
                classes={{ root: "skeleton-table-circle-btn" }}
              />
            </div>
          </div>
        }
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        paginationIconNext={<IconSelector svg="ChevronRight" />}
        paginationIconPrevious={<IconSelector svg="ChevronLeft" />}
        paginationIconFirstPage={<IconSelector svg="BackToFirst" />}
        paginationIconLastPage={<IconSelector svg="ForwardToLast" />}
        paginationComponentOptions={{
          rowsPerPageText: t("common.table.rowsPerPageText"),
          rangeSeparatorText: t("common.table.rangeSeparatorText"),
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        selectableRows={configTable.hasSelectableRows}
        selectableRowsHighlight
        selectableRowsComponent={forwardRef((props, ref) => (
          <Checkbox
            {...props}
            ref={ref}
            icon={
              <IconSelector svg={"Checkbox"} classname="icon--table-checkbox" />
            }
            checkedIcon={
              <IconSelector
                svg={"CheckedCheckbox"}
                classname="icon--table-checkbox-checked"
              />
            }
            indeterminateIcon={<IconSelector svg={"CheckboxIndeterminate"} />}
          />
        ))}
        selectableRowsComponentProps={{
          indeterminate: (isIndeterminate) => isIndeterminate,
        }}
        onSelectedRowsChange={handleSelectedRows}
        clearSelectedRows={clearRows}
        onRowClicked={(row) => onClickRow(row, redirectEditOnClick)}
        pointerOnHover={userPermissions[configTable.permission].update}
        sortServer
        onSort={handleSort}
        sortIcon={<IconSelector svg="ArrowDown" classname="icon--table-sort" />}
        noHeader
        subHeader
        subHeaderComponent={
          <>
            <TableHeader
              params={{
                columns: columns,
                hideColumn: hideColumn,
                setHideColumn: setHideColumn,
                searchText: searchText,
                setSearchText: setSearchText,
                searchUpdateFlag: searchUpdateFlag,
                loading: loading,
                selectedRows: selectedRows,
                action: action,
                setAction: setAction,
                openConfirmModal: openActionModal,
                onExportTable: onExportTable,
                hasSelectableRows: configTable.hasSelectableRows,
                ...params,
                configTable,
                exportOptions,
              }}
            />
            <Modal
              type="multiAction"
              params={{
                tableType: tableType,
                openModal: confirmAction,
                closeModal: closeActionModal,
                confirm: onConfirmAction,
                cancel: closeActionModal,
                resultMessage: resultAction,
                numberItems: selectedRows.length,
                action: action,
              }}
            />
            <Modal
              type="exportResult"
              params={{
                openModal: exportResult,
                closeModal: closeExportResult,
              }}
            />
          </>
        }
        noDataComponent={
          tableError ? (
            <div className="c-table-error">
              <Avatar classes={{ root: "table-icon-wrapper" }}>
                <IconSelector svg="Warning" classname="icon--table" />
              </Avatar>
              <h1 className="table-error__title">
                {t("common.table.error.title")}
              </h1>
              <p className="table-error__text">{t("common.table.error.text")}</p>
              <Button
                className="btn-primary btn-primary--large"
                onClick={handleReloadTable}
              >
                {t("common.table.error.btnText")}
              </Button>
            </div>
          ) : (
            <div className="c-table-error--no-result">
              <Avatar
                classes={{
                  root: "table-icon-wrapper table-icon-wrapper--no-result",
                }}
              >
                <IconSelector svg="NoResultFound" classname="icon--no-result" />
              </Avatar>
              <h1 className="table-error__title">
                {t(`${tableType}.table.noData.${noDataTitle}`)}
              </h1>
            </div>
          )
        }
      />
    </div>
  );
}

export default CustomTable;
