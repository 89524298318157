import { useState } from "react";
import DatePickerHeaderTable from "../../table_header_elements/DatePickerHeaderTable";
import HideColumnsMenu from "../../table_header_elements/HideColumnsMenu";
import NewTableItemBtn from "../../table_header_elements/NewTableItemBtn";
import SearchTableBtn from "../../table_header_elements/SearchTableBtn";
import dayjs from "dayjs";

export function HeadeAlertTable({ params }) {
  const { formik, Update } = params;

  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const handleSubmit = () => {
    formik.values.dateStart = dateStart;
    formik.values.dateEnd = dateEnd;
    Update();
  };

  const handleDateChange = ({ dateStart, dateEnd }) => {
    setDateStart(dayjs(dateStart).format("DD/MM/YYYY"));
    setDateEnd(dayjs(dateEnd).format("DD/MM/YYYY"));
  };

  return (
    <div className="content-form-alert">
      <DatePickerHeaderTable Update={Update} onChange={handleDateChange} />
      <HideColumnsMenu params={params} />
      <SearchTableBtn
        type="submit"
        onClick={handleSubmit}
        params={params}
      />
      <NewTableItemBtn params={params} />,
    </div>
  );
}
