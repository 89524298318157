import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import IconSelector from "../../IconSelector";
import QRCode from 'react-qr-code';
import { useCookies } from "react-cookie";


function DownloadApp() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cookies] = useCookies();
  const url = window.location.href;
  const downloadLink = "https://play.google.com/store/apps/details?id=com.cittua&pli=1";

  const host = url.split("/")[3];
  const dispatch = useDispatch();
  const localStorageUserLocations = cookies.userLocals ?? []



  // const textI18nHasLocalLength = {
  //   pageTitle: t("locations.showLocations.titleHasLocal"),
  //   qrCodeBox: {
  //     title: t("locations.showLocations.qrCodeBox.titleHasLocal"),
  //     text: t("locations.showLocations.qrCodeBox.textHasLocal"),
  //   }
  // }
  // const textI18nNoLocalLength = {
  //   pageTitle: t("locations.showLocations.titleNoLocal"),
  //   qrCodeBox: {
  //     title: t("locations.showLocations.qrCodeBox.titleNoLocal"),
  //     text: t("locations.showLocations.qrCodeBox.textNoLocal"),
  //   }
  // }

  // const textI18n = localStorageUserLocations && localStorageUserLocations.length ? textI18nHasLocalLength : textI18nNoLocalLength;

  return (
    <div className='info-locations-container'>

      <div className="info-locations-header">
        <h5 className="badge-number">3</h5>
        <p>{t('create-account.guidelines.part3')}</p>
      </div>

      <div className="info-locations-wrapper">
        <div className="locations-wrapper">
          <div className="intro">
            <div className="intro-wrapper">
              <div className="intro-text">
                <h3>{t('download.title')} </h3>
                <span>{t('download.subtitle')}</span>
              </div>

              <div className="intro-image">
                <IconSelector svg="Congratz" />
              </div>
            </div>

            <div className="intro-highlight">
              <h4>{t('download.highlight')}</h4>
            </div>

            <div className="dashed-card">
              <p>{t('download.description')}</p>

            </div>
          </div>

          <div className="download-app-card">
            <div className="download-app-card-content">
              <h3>{t('download.card.title')}</h3>
              <p className="download-app-card-description">{t('download.card.description')}</p>
              <ul className="feature-list">
                <li>{t('download.card.features.feat1')}</li>
                <li>{t('download.card.features.feat2')}</li>
              </ul>
            </div>

            <div className="qr-code-box">
              <div className="image-placeholder">
                <QRCode value={downloadLink} className="" />
              </div>
            </div>
          </div>

        </div>





        <button
          className="prev-btn"
          onClick={() => navigate(`/user-area/info-locations`)}
        >
          <IconSelector svg="ChevronLeft" /> {t('common.prev')}
        </button>
      </div>
    </div>
  );
}

export default DownloadApp;
