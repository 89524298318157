import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//import Tooltip from '@mui/material/Tooltip'
import Button from "@mui/material/Button";

import IconSelector from "./IconSelector";
import { TextSelector } from "./TextSelector";
import BtnLoadingText from "./BtnLoadingText";

//TODO: Refatorar todo o código desse componente e repensar a maneira feita

function Modal({ type, params }) {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.uiActions.theme);

  if (type === "recovery") {
    const recoveryInitialValues = {
      email: "",
    };

    const recoveryValidation = (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = t("common.inputErrors.fieldRequired");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("common.inputErrors.invalidEmail");
      }
      return errors;
    };

    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          paper: "modal modal--medium",
        }}
      >
        <>
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content">
            <h2>
              {params.changeContentFlag
                ? t("login.modal.recovery.successTitle")
                : t("login.modal.recovery.title")}
            </h2>
            <p className="recovery-modal-text">
              {params.changeContentFlag
                ? t("login.modal.recovery.successText")
                : t("login.modal.recovery.text")}
            </p>
            {!params.changeContentFlag && (
              <Formik
                initialValues={recoveryInitialValues}
                validate={(values) => recoveryValidation(values)}
                onSubmit={(values, actions) =>
                  params.changeContentFunction(values, actions)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                }) => (
                  <form className="recovery-modal-form" onSubmit={handleSubmit}>
                    <div className="c-input c-input--padding-bottom">
                      <label className="label" htmlFor="email">
                        {t("login.modal.recovery.field")}
                      </label>
                      <input
                        id="email"
                        className={
                          errors.email && touched.email
                            ? "input input--error"
                            : "input"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        placeholder={t("login.modal.recovery.placeholder")}
                      ></input>
                      <div
                        className={
                          errors.email && touched.email
                            ? "c-error-text"
                            : "c-error-text c-error-text--hidden"
                        }
                      >
                        <IconSelector
                          svg={"ErrorWarningLine"}
                          classname={"icon--warning"}
                        />
                        <span className="error-text">{errors.email}</span>
                      </div>
                      {params.errorFlag && (
                        <div className="c-error-message">
                          <IconSelector
                            svg={"ErrorWarningLine"}
                            classname={"icon--warning"}
                          />
                          <span className="error-message">
                            {params.errorMessage}
                          </span>
                        </div>
                      )}
                    </div>
                    <Button
                      type="submit"
                      classes={{ root: "btn-primary btn-primary--large" }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <BtnLoadingText />
                      ) : (
                        t("login.modal.recovery.btn")
                      )}
                    </Button>
                  </form>
                )}
              </Formik>
            )}
            {params.changeContentFlag && (
              <div className="c-controlBtns">
                <Button
                  className="btn-primary btn-primary--large"
                  onClick={params.closeModal}
                >
                  {t("login.modal.recovery.closeBtn")}
                </Button>
                <Button
                  className="btn-tertiary btn-tertiary--large btn-tertiary--margin-left"
                  onClick={params.backFunction}
                >
                  {t("login.modal.recovery.sendAgainBtn")}
                </Button>
              </div>
            )}
          </div>
        </>
      </Dialog>
    );
  }
  if (type === "scrollText") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        scroll="paper"
        classes={{
          paper: "modal modal--large",
        }}
      >
        <DialogTitle
          classes={{
            root: "modal__title",
          }}
        >
          <>
            <Button className="btn-modal" onClick={params.closeModal}>
              <IconSelector svg={"CloseLine"} classname={"icon--close"} />
            </Button>
            <h2>{t(`login.modal.${params.changeContentFlag}.title`)}</h2>
          </>
        </DialogTitle>
        <DialogContent
          classes={{
            root: "c-modal-content c-modal-content--margin-right",
          }}
        >
          <TextSelector type={params.changeContentFlag} />
        </DialogContent>
      </Dialog>
    );
  }
  if (type === "recoveryResult") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          {/*TODO: Passar texto para o translation*/}
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content c-modal-content--justify-center">
            <h1 className="recovery-result__title">
              {t("recovery.modal.newPasswordSuccess")}
            </h1>
            <p className="recovery-result__text">
              {t("recovery.modal.newPasswordSuccessMsg")}
            </p>
            <Button
              className="btn-primary btn-primary--large"
              onClick={params.closeModal}
            >
              {t("recovery.modal.back")}
            </Button>
          </div>
        </>
      </Dialog>
    );
  }
  if (type === "smsVerification") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content-sms-code c-modal-content--justify-center">
            <h1 className="sms-verification__title">
              {t("smsVerification.title")}
            </h1>
            <p className="sms-verification__text"
              dangerouslySetInnerHTML={{
                __html: t("smsVerification.message",
                  { phoneNumber: params.phoneNumber })
              }}
            ></p>

            {/* Código SMS */}
            <div className="sms-code-input">
              <label className="sms-code-label">
                {t("smsVerification.label")}
              </label>
              <div>
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    maxLength={1}
                    className={`sms-code-input__field ${params.hasError ? "error" : ""}`}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    value={params.code?.[index] || ""}
                    onChange={(e) => {
                      params.onInputChange(e.target.value, index);
                      if (e.target.value && index < 5) {
                        document.getElementById(`code-input-${index + 1}`).focus();
                      }
                    }}
                    id={`code-input-${index}`}
                  />
                ))}
              </div>
            </div>

            {/* Botão para confirmar o código */}
            <Button
              className="btn-primary btn-primary--large-full-width"
              disabled={params.code.some((digit) => digit === "") || params.code.length === 0}
              onClick={() => {
                params.onConfirm(); // Chama a função passada como parâmetro
                params.clearCode();
                params.startTimerSMS();
              }}
            >
              {t("smsVerification.confirmButton")}
            </Button>

            {params.timer < 1 && (
              <button
                className="btn-secondary resend-code-btn"
                onClick={() => {
                  params.clearCode();
                  params.onResend();
                  params.openResendModal();
                }}
                disabled={params.timer > 0}
              >
                {params.timer > 0
                  ? t("smsVerification.resendCountdown", {
                    time: `${Math.floor(params.timer / 60)}:${String(
                      params.timer % 60
                    ).padStart(2, "0")}`,
                  })
                  : t("smsVerification.resendButton")}
              </button>
            )}

            {/* Contador do Timer */}
            {params.openModal && params.timer > 0 && params.isTimerStarted && (
              <div className="sms-timer">
                <IconSelector svg="ErrorWarningLine" classname="icon--error" />
                {t("smsVerification.timerText", {
                  time: `${Math.floor(params.timer / 60)}:${String(params.timer % 60).padStart(2, "0")}`,
                })}
              </div>
            )}

            {/* Botão para reenviar código */}

          </div>
        </>
      </Dialog>
    );
  }
  if (type === "resendCode") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          <div className="c-modal-content c-modal-content--justify-center">
            <p className="resend-code__text"
              dangerouslySetInnerHTML={{
                __html: t("resendCode.message",
                  { phoneNumber: params.phoneNumber })
              }}
            ></p>
            <p className="resend-code__subtext">
              {t("resendCode.waitMessage")}
            </p>
            <Button
              className="btn-primary resend-code__button"
              onClick={params.closeModal}
            >
              {t("resendCode.button")}
            </Button>
          </div>
        </>
      </Dialog>
    );
  }
  if (type === "formCreateResult") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content c-modal-content--justify-center">
            <IconSelector svg={"SuccessCreate"} />
            <h1 className="recovery-result__title">
              {params.actionSuccess
                ? t(`${params.formType}.${params.actionType}.success.title`)
                : t(`${params.formType}.${params.actionType}.failed.title`)}
            </h1>
            <p className="recovery-result__text">
              {params.actionSuccess
                ? t(`${params.formType}.${params.actionType}.success.text`)
                : params.actionMessage !== null
                  ? params.actionMessage
                  : t(`${params.formType}.${params.actionType}.failed.text`)}
            </p>
            <Button
              className="btn-primary btn-primary--large-full-width"
              onClick={params.closeModal}
            >
              {t("common.form.btn.okBtn")}
            </Button>
          </div>
        </>
      </Dialog>
    );
  }
  if (type === "multiAction") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content">
            {params.resultMessage === null ? (
              <>
                {params.numberItems === 0 ? (
                  <>
                    <p className="recovery-result__text">
                      {t("headerTableBtn.modal.noItems")}
                    </p>
                    <Button
                      className="btn-primary btn-primary--large"
                      onClick={params.cancel}
                    >
                      {t("common.form.btn.okBtn")}
                    </Button>
                  </>
                ) : (
                  <>
                    <p className="recovery-result__text">
                      {t(
                        `${params.tableType}.table.multiAction.${params.action}`,
                        { count: params.numberItems }
                      )}
                    </p>
                    <div className="c-controlBtns">
                      <Button
                        className="btn-primary btn-primary--large"
                        onClick={params.confirm}
                      >
                        {t("common.form.btn.okBtn")}
                      </Button>
                      <Button
                        className="btn-tertiary btn-tertiary--large btn-tertiary--margin-left"
                        onClick={params.cancel}
                      >
                        {t("common.form.btn.cancelBtn")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <p className="recovery-result__text">{params.resultMessage}</p>
                <Button
                  className="btn-primary btn-primary--large"
                  onClick={params.closeModal}
                >
                  {t("common.form.btn.okBtn")}
                </Button>
              </>
            )}
          </div>
        </>
      </Dialog>
    );
  }
  if (type === "exportResult") {
    return (
      <Dialog
        open={params.openModal}
        onClose={params.closeModal}
        classes={{
          root: `theme--${theme}`,
          paper: "modal modal--medium",
        }}
      >
        <>
          <Button className="btn-modal" onClick={params.closeModal}>
            <IconSelector svg={"CloseLine"} classname={"icon--close"} />
          </Button>
          <div className="c-modal-content">
            <p className="recovery-result__text">
              {t("headerTableBtn.modal.exportTableError")}
            </p>
            <Button
              className="btn-primary btn-primary--large"
              onClick={params.closeModal}
            >
              {t("common.form.btn.okBtn")}
            </Button>
          </div>
        </>
      </Dialog>
    );
  }
}

export default Modal;
