import { useNavigate } from "react-router-dom";
import UserLocalCard from "../components/UserLocalCard";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import cittuaLogo from "../assets/Logo.svg";
import Modal from "../../../components/Users/components/Modal";
import IconSelector from "../../IconSelector";

function InfoLocals() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(0);
  const [localStorageUserLocations, setLocalStorageUserLocations] = useState([]); 
  const dispatch = useDispatch();
  const url = window.location.href;
  const host = url.split("/")[3];

  const textI18nHasLocalLength = {
    pageTitle: t("locations.showLocations.titleHasLocal"),
    qrCodeBox: {
      title: t("locations.showLocations.qrCodeBox.titleHasLocal"),
      text: t("locations.showLocations.qrCodeBox.textHasLocal"),
    },
  };
  const textI18nNoLocalLength = {
    pageTitle: t("locations.showLocations.titleNoLocal"),
    qrCodeBox: {
      title: t("locations.showLocations.qrCodeBox.titleNoLocal"),
      text: t("locations.showLocations.qrCodeBox.textNoLocal"),
    },
  };


  const loadLocalsFromStorage = () => {
    const storedLocals = JSON.parse(localStorage.getItem("userLocals")) || [];
    setLocalStorageUserLocations(storedLocals);
  };


  const handleDeleteLocal = (localToDelete) => {
    const updatedLocals = localStorageUserLocations.filter(
      (local) => local.nome !== localToDelete.nome
    );
    setLocalStorageUserLocations(updatedLocals);
    localStorage.setItem("userLocals", JSON.stringify(updatedLocals)); 
  };

  useEffect(() => {
    loadLocalsFromStorage();
  }, []);

  const textI18n = localStorageUserLocations.length
    ? textI18nHasLocalLength
    : textI18nNoLocalLength;

  const handleCreateLocal = () => {
    if (localStorageUserLocations.length >= 3) {
      handleNextModal();
      return;
    }
    navigate(`/${host}/create-locations`);
  };

  const handleNextModal = () => setShowModal((prev) => prev + 1);
  const handleCloseModal = () => setShowModal(0);

  return (
    <>
      {showModal === 1 && (
        <Modal
          title="Assine o plano Starter para cadastrar mais locais"
          text="Plano inicial que permite um maior controle dos seus locais"
          icon={cittuaLogo}
          type=""
          btnActionText={"Assinar"}
          handleCloseModal={handleCloseModal}
          handleButtonAction={handleNextModal}
          iconCSSClass={"plan-modal-icon"}
          titleCSSClass={"plan-modal-title"}
          textCSSClass={"plan-modal-description"}
        />
      )}

      <div className="info-locations-container">
        <div className="info-locations-header">
          <h5 className="badge-number">{localStorageUserLocations.length}</h5>
          <p>{t("create-account.guidelines.part2")}</p>
        </div>

        <div className="info-locations-wrapper">
          <div className="locations-wrapper">
            <div className="intro">
              {(localStorageUserLocations.length === 0 ||
                localStorageUserLocations.length === 1) && (
                <span>
                  <IconSelector svg="BlinkEyes" />
                  {t("locations.subtitle")}
                </span>
              )}
              {localStorageUserLocations.length === 2 && (
                <span>
                  <IconSelector svg="HappyFace" />
                  {t("locations.subtitle")}
                </span>
              )}
              {localStorageUserLocations.length > 2 && (
                <div className="locals-done">
                  <IconSelector svg="HeartEyes" />
                  <div>
                    <p>{t("locations.done.title")}</p>
                    <p>{t("locations.done.subtitle")}</p>
                  </div>
                </div>
              )}
              <div className="features">
                <p>
                  <IconSelector svg="ChevronRight" />{" "}
                  {t("locations.showLocations.features.firstLine")}
                </p>
                <p>
                  <IconSelector svg="ChevronRight" />{" "}
                  {t("locations.showLocations.features.secondLine")}
                </p>
                <p>
                  <IconSelector svg="ChevronRight" />{" "}
                  {t("locations.showLocations.features.thirdLine")}
                </p>
              </div>
            </div>
            <div className="locals-list">
              <h3 className="locals-list-title">
                {t("locations.showLocations.titleHasLocal")}
              </h3>
              {localStorageUserLocations.map((local, index) => (
                <div key={index} className="local-card">
                  <UserLocalCard
                    local={local}
                    onDelete={() => handleDeleteLocal(local)} 
                  />
                </div>
              ))}
            </div>
          </div>

          <button
            className="add-location-btn"
            onClick={handleCreateLocal}
          >
            <IconSelector svg="Plus" /> {t("locations.addLocation.btn")}
          </button>

          {localStorageUserLocations.length > 0 && (
            <button
              className="next-btn"
              onClick={() => navigate(`/user-area/download-app`)}
            >
              {t("common.next")} <IconSelector svg="ChevronRight" />
            </button>
          )}
        </div>

        {showModal === 2 && (
          <Modal
            title="O plano Starter será disponibilizado em breve"
            text="Entraremos em contato através do e-mail cadastrado e do aplicativo Cittua"
            icon={cittuaLogo}
            handleCloseModal={handleCloseModal}
            iconCSSClass={"plan-modal-icon"}
            titleCSSClass={"plan-modal-title"}
            textCSSClass={"plan-modal-description"}
          />
        )}
      </div>
    </>
  );
}

export default InfoLocals;
