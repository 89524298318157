import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";

import MapComponent from "../Map";
import IconSelector from "../IconSelector";
import { getMapInitialOptions } from "../../utils/dataAPI";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import RainfallScale from "../RainfallScale";

function MapPolygonInput({ params }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    id,
    formType,
    formProps,
    validations,
    previewForecasts,
    mapTimeSettings,
    isPermissionActive,
  } = params;
  const { date, setDate, time, setTime } = mapTimeSettings;
  const [loading, setLoading] = useState(true);
  const [initialMapSettings, setInitialMapSettings] = useState(null);
  const [mapError, setMapError] = useState(false);

  const [dialogOpenLegend, setDialogOpenLegend] = useState(false);

  const [dialogOpenCalendar, setDialogOpenCalendar] = useState(false);

  const weekDay = (day) => {
    const newDay = {
      D: "DOM",
      2: "SEG",
      3: "TER",
      4: "QUA",
      5: "QUI",
      6: "SEX",
      S: "SAB",
    };

    return newDay.hasOwnProperty(day.charAt(0))
      ? newDay[day.charAt(0)]
      : day.charAt(0).toUpperCase();
  };

  const handleMapChange = (value, coordinatesOfTheRemovedPolygons) => {
    const existingPolygons = formProps.values[id];
    formProps.setFieldValue(id, existingPolygons);

    if (coordinatesOfTheRemovedPolygons) {
      const polygons = existingPolygons.filter(
        (coordinates) =>
          coordinates.geometry.coordinates !== coordinatesOfTheRemovedPolygons
      );

      formProps.setFieldValue(id, polygons);

      if (coordinatesOfTheRemovedPolygons.length === existingPolygons.length) {
        formProps.setFieldValue(id, "");
        return;
      }
    } else {
      const newListOfPolygons = [...existingPolygons, ...value];
      formProps.setFieldValue(id, newListOfPolygons);
    }
  };

  const openDialogLegend = () => {
    setDialogOpenLegend(true);
  };

  const openDialogCalendar = () => {
    setDialogOpenCalendar(true);
  };

  const closeDialogLegend = () => {
    setDialogOpenLegend(false);
  };

  const closeDialogCalendar = () => {
    setDialogOpenCalendar(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const initialData = await getMapInitialOptions(dispatch);
        if (initialData && initialData.success) {
          setInitialMapSettings(initialData.data);
        } else {
          setMapError(true);
        }
        setLoading(false);
      } catch (error) {
        setMapError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const initialData = await getMapInitialOptions(dispatch);
        if (initialData && initialData.success) {
          setInitialMapSettings(initialData.data);
        } else {
          setMapError(true);
        }
        setLoading(false);
      } catch (error) {
        setMapError(true);
        setLoading(false);
      }
    };

    if (mapError) {
      setMapError(false);
      fetchData();
    }
  }, [date, time]);

  return (
    <>
      <div className="header-container-map">
        <label className="colored-label">
          {t(`${formType}.fields.${id}`)}
          {validations.filter((rule) => rule.type === "required").length > 0 &&
            "*"}
        </label>
      </div>
      <div className="c-map-input">
        <div className="c-map-input-content">
          {loading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              classes={{ root: "skeleton-map-input" }}
            />
          ) : mapError ? (
            <div className="c-map-input-error">
              <div className="error-page-content">
                <Avatar classes={{ root: "error-page__icon-wrapper" }}>
                  <IconSelector svg="Warning" classname="icon--warning-big" />
                </Avatar>
                <div className="error-page__text-block">
                  <h1 className="error-page-title">
                    {t("nwpForecast.mapError.title")}
                  </h1>
                  <p className="error-page-text">
                    {t("nwpForecast.mapError.text")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <MapComponent
              params={{
                initialMapSettings: initialMapSettings,
                lastUpdateFlag: false,
                drawingControls: true,
                onError: setMapError,
                showLegendDialog: openDialogLegend,
                showCalendarDialog: openDialogCalendar,
                isPermissionActive: isPermissionActive,
                date: date,
                time: time,
                previewForecasts: previewForecasts,
                inputParams: {
                  handleMapChange: handleMapChange,
                  mapValue: formProps.values[params.id],
                },
              }}
            />
          )}
        </div>
        <div>
          {isPermissionActive ? (
            <>
              <Dialog open={dialogOpenCalendar} onClose={closeDialogCalendar}>
                <DialogActions>
                  <Button
                    onClick={() => closeDialogCalendar(false)}
                    className="btn-modal"
                  >
                    <IconSelector
                      svg={"CloseLine"}
                      classname="icon--close-modal-map"
                    />
                  </Button>
                </DialogActions>
                <DialogTitle>
                  <div className="c-map-calendar-container">
                    <div className="text-header-calendar">
                      {t("mapModal.forecastOfTheDay")}
                    </div>
                    <DateCalendar
                      className="map-date-calendar-control"
                      dayOfWeekFormatter={weekDay}
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      maxDate={
                        initialMapSettings &&
                        dayjs(initialMapSettings.max_dt_available)
                      }
                      minDate={
                        initialMapSettings &&
                        dayjs(initialMapSettings.min_dt_available)
                      }
                      disabled={loading}
                    />
                  </div>
                </DialogTitle>
              </Dialog>
              <Dialog open={dialogOpenLegend} onClose={closeDialogLegend}>
                <DialogActions>
                  <Button
                    onClick={() => closeDialogLegend(false)}
                    className="btn-modal"
                  >
                    <IconSelector
                      svg={"CloseLine"}
                      classname="icon--close-modal-map"
                    />
                  </Button>
                </DialogActions>
                <DialogTitle>
                  <div className="c-map-modal-container">
                    <div className="text-header-legend">
                      {t("mapModal.legend")}
                    </div>
                    <RainfallScale
                      containerClass={"rain-fall-legend"}
                      scaleTitle={t("mapModal.legendOfRain")}
                      titleClass={"text-legend"}
                      propLevelClass={"custom-level-class"}
                    />
                    <div className="modal-legend-wind">
                      <p className="scale-title">
                        {t("mapModal.legendOfTheWind")}
                      </p>
                      <div className="wind-legend">
                        <div className="legend">
                          <IconSelector svg={"NoWind"} />
                          <p>{t("nivelWind.noWind")}</p>
                        </div>
                        <div className="legend">
                          <IconSelector svg={"VeryWeak"} />
                          <p>{t("nivelWind.veryWeak")}</p>
                        </div>
                        <div className="legend">
                          <IconSelector svg={"Weak"} />
                          <p>{t("nivelWind.weak")}</p>
                        </div>
                        <div className="legend">
                          <IconSelector svg={"Moderate"} />
                          <p>{t("nivelWind.moderate")}</p>
                        </div>
                        <div className="legend">
                          <IconSelector svg={"VeryStrong"} />
                          <p>{t("nivelWind.veryStrong")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogTitle>
              </Dialog>
            </>
          ) : (
            <DateCalendar
              className="map-date-calendar-control"
              dayOfWeekFormatter={weekDay}
              value={date}
              onChange={(newValue) => setDate(newValue)}
              maxDate={
                initialMapSettings && dayjs(initialMapSettings.max_dt_available)
              }
              minDate={
                initialMapSettings && dayjs(initialMapSettings.min_dt_available)
              }
              disabled={loading}
            />
          )}
          {!isPermissionActive && (
            <>
            <div className="c-time-input map-time-group">
              <p className="time-input-label">
                {t("nwpForecast.time_input.label")}
              </p>
              <TimePicker
                ampm={false}
                views={["hours"]}
                format="HH:00"
                className={`map-time-input ${
                  formProps.errors.publication_time && formProps.touched.publication_time ? "error-border" : ""
                }`}
                value={formProps.values.publication_time
                  ? dayjs(`2025-01-01T${formProps.values.publication_time}`)
                  : null}
                onChange={(newValue) => {
                  const formattedTime = dayjs(newValue).format("HH:mm");
                  formProps.setFieldValue("publication_time", formattedTime);
                } }
                disabled={loading} />
            </div>
            <div
              className={formProps.errors.publication_time && formProps.touched.publication_time
                ? "c-error-text time-error"
                : "c-error-text c-error-text--hidden"}
            >
              <IconSelector
                svg="ErrorWarningLine"
                classname="icon--warning" />
              <span className="error-text">{formProps.errors.publication_time}</span>
            </div></>
          )}
        </div>
      </div>
      <div
        className={
          formProps.errors[id] && formProps.touched[id]
            ? "c-error-text-map"
            : "c-error-text c-error-text--hidden"
        }
      >
        <IconSelector svg={"ErrorWarningLine"} classname={"icon--warning"} />
        <span className="error-text">{formProps.errors[id]}</span>
      </div>
    </>
  );
}

export default MapPolygonInput;
