import '../css/CreateLocal.css'
import UserLocalForm from '../components/UserLocalForm'
import { useTranslation } from 'react-i18next';

export default function CreateLocal() {
  const { t } = useTranslation();

  return (
    <div className='form-container'>
      <h1>{t("locations.addLocation.title")}</h1>
      <p>{t("locations.addLocation.text")}</p>
      <UserLocalForm buttonAddText={`+ ${t("locations.addLocation.btn")}`} />
    </div>
  )
}
