import { Link, Outlet, useLocation } from 'react-router-dom'
import Cittua_Logo from '../assets/Logo.svg'
import profileIcon from '../assets/Profile.svg'
import localIcon from '../assets/Location.svg'
import exitIcon from '../assets/Exit.svg'
import '../css/index.css'
import { useTranslation } from 'react-i18next'
import { Cookies } from 'react-cookie'
import { useState } from 'react'
import IconSelector from '../../IconSelector'

function UsersLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const host = location.pathname.split('/')[1];
  const pageLocation = location.pathname.split('/')[2];
  const cookies = new Cookies();
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const accountsPageActive = pageLocation.includes('account') && 'active' || "";
  const locationsPageActive = (pageLocation.includes('locations') || pageLocation.includes('download-app')) && 'active' || "";

  const pageTitle = pageLocation.includes('account') ? t("simpleHeader.title.profile") : t("simpleHeader.title.myPlaces");

  const handleLogout = () => {
    cookies.remove('user');
    cookies.remove('userLocals');
  }

  return (
    <div className="app-container">
      {sidebarOpen && (
        <aside className={`sidebar ${sidebarOpen ? 'open' : ''}`}>

          <nav>
            <div className="sidebar-mobile-menu">
              <button className='hamburger-menu' onClick={toggleSidebar}>
                <IconSelector svg="Menu" />
              </button>
            </div>
            <ul>
              <li className={accountsPageActive}>
                <Link to={`/${host}/info-account`} className='menu-link'>
                  <IconSelector svg="Person" />
                  Perfil
                </Link>
              </li>
              <li className={locationsPageActive}>
                <Link to={`/${host}/info-locations`} className='menu-link'>
                  <IconSelector svg="LocationPin" />
                  Meus locais
                </Link>
              </li>
              <div className='sidebar-breakpoint'></div>
              <li>
                <Link to="/" onClick={() => handleLogout()} className='menu-link'>
                  <IconSelector svg="Exit" />
                  Sair
                </Link>
              </li>
            </ul>
          </nav>
        </aside>
      )}
      <main className="main-content">
        <header className='header'>
          <button className='hamburger-menu' onClick={toggleSidebar}>
            <IconSelector svg="Menu" />
          </button>
          <div className='logo-container'>
            <img className='logo' src={Cittua_Logo} />
          </div>
        </header>
        <p className='page-title'>{pageTitle}</p>
        <section className="content-container">
          <main className='content'>
            <Outlet />
          </main>
        </section>
      </main>
    </div>
  );
}

export default UsersLayout
