import profileIcon from '../assets/Profile_Roxo.svg';
import mailIcon from '../assets/Mail.svg';
import { useTranslation } from "react-i18next";
import phoneIcon from '../assets/Phone.svg';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getMobileUserData } from '../../../utils/dataAPI'

function InfoAccount() {
  const location = useLocation();
  const host = location.pathname.split("/")[1];
  const { t } = useTranslation();
  

  const [userData, setUserData] = useState({
    name: "Carregando...",
    phone: "Carregando...",
    email: "Carregando...",
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await getMobileUserData();

      if (response && response.success && response.data) {
        setUserData({
          name: `${response.data.first_name || ""} ${response.data.last_name || ""}`.trim() || "Nome não informado",
          phone: response.data.phone || response.data.mobile || "Telefone não informado",
          email: response.data["e-mail"] || "E-mail não informado",
        });
      } else {
        console.warn("API falhou, buscando dados do usuário no localStorage...");
        const savedUserInfo = localStorage.getItem("userInfo");

        if (savedUserInfo) {
          setUserData(JSON.parse(savedUserInfo));
        } else {
          console.error("Nenhum dado do usuário encontrado no localStorage.");
          setUserData({
            name: "Nome não informado",
            phone: "Telefone não informado",
            email: "E-mail não informado",
          });
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      const savedUserInfo = localStorage.getItem("userInfo");

      if (savedUserInfo) {
        setUserData(JSON.parse(savedUserInfo));
      } else {
        console.error("Nenhum dado do usuário encontrado no localStorage.");
        setUserData({
          name: "Nome não informado",
          phone: "Telefone não informado",
          email: "E-mail não informado",
        });
      }
    } finally {
      setIsLoading(false); 
    }
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return "Telefone não informado";
    const cleanedPhone = phone.replace(/\D/g, "");

    const hasCountryCode = cleanedPhone.startsWith("55");
    const formattedPhone = hasCountryCode ? cleanedPhone.slice(2) : cleanedPhone;

    if (formattedPhone.length === 11) {
      const areaCode = formattedPhone.slice(0, 2);
      const prefix = formattedPhone.slice(2, 7);
      const lineNumber = formattedPhone.slice(7);

      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }

    if (formattedPhone.length === 10) {
      const areaCode = formattedPhone.slice(0, 2);
      const prefix = formattedPhone.slice(2, 6);
      const lineNumber = formattedPhone.slice(6);

      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }

    return "Formato de telefone inválido";
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (isLoading) {
    return <div className="loading">Carregando informações...</div>;
  }
  
  return (
    <div className="form-container">
      <h1>{t("settings.personalData")}</h1>
      <p>{t("settings.updatePersonalData")}</p>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={profileIcon} alt="Profile icon" />
        </div>
        <span>
          <strong>{t("settings.Name")}</strong> {userData.name || "Nome não informado"}
        </span>
      </div>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={phoneIcon} alt="Phone icon" />
        </div>
        <span>
          <strong>{t("settings.Telephone")}</strong> {formatPhoneNumber(userData.phone)}
        </span>
      </div>

      <div className="info-item">
        <div className="info-account-icon">
          <img src={mailIcon} alt="Mail icon" />
        </div>
        <span>
          <strong>E-mail:</strong> {userData.email || "E-mail não informado"}
        </span>
      </div>

      <Link to={`/${host}/edit-account`} className="edit-button">
      {t("settings.EditProfile")}
      </Link>
    </div>
  );
}

export default InfoAccount;
