import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useDispatch } from "react-redux";

import IconSelector from "../../IconSelector";
import BtnLoadingText from "../../BtnLoadingText";
import { updateMobileUserData } from "../../../utils/dataAPI";

const initialValues = {
  name: "",
  country: "+55",
  cellPhone: "",
};

function EditAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const host = location.pathname.split("/")[1];
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryData = data
          .filter((country) => country.idd?.root)
          .map((country) => ({
            flag: country.flags.png || "",
            code: `${country.idd.root || "+5"}${country.idd.suffixes?.[0] || "5"
              }`,
          }));
        setCountryCodes(countryData);
      })
      .catch((error) => console.error("Error fetching country codes:", error));
  }, []);

  const dataValidation = (values) => {
    const errors = {};
    if (!values.name) errors.name = t("common.inputErrors.fieldRequired");
    if (!values.cellPhone) {
      errors.cellPhone = t("common.inputErrors.fieldRequired");
    } else if (!/^\(?\d{2}\)?[\s-]?\d{4,5}[-]?\d{4}$/.test(values.cellPhone)) {
      errors.cellPhone = t("common.inputErrors.cellPhoneError");
    }
    return errors;
  };

  const submitData = async (values, actions) => {
    try {
      const fullPhoneNumber = `${values.country}${values.cellPhone}`;

      const payload = {
        first_name: values.name.split(" ")[0],
        last_name: values.name.split(" ").slice(1).join(" ") || " ",
        phone: fullPhoneNumber,
        birth_date: "1900-01-01",
        reduced_mobility: false,
        deficiency: {
          has_deficiency: false,
          deficiency_list: [],
        }
      };
      const response = await updateMobileUserData({ form: payload }, dispatch);
      if (response.success) {
        alert("Dados atualizados com sucesso!");
        navigate(`/${host}/info-account`);
      } else {
        alert("Erro ao atualizar os dados.");
      }

      actions.setSubmitting(false);
    } catch (error) {
      console.error("Erro ao atualizar os dados:", error);
      alert("Erro inesperado ao atualizar os dados.");
      actions.setSubmitting(false);
    }
  };


  return (
    <div className="form-container">

      <Formik
        initialValues={initialValues}
        validate={(values) => dataValidation(values)}
        onSubmit={(values, actions) => submitData(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form className="login-form internal-form" onSubmit={handleSubmit}>
            <h3>{t('editAccount.name')}</h3>
            {/* Nome */}
            <div className="c-input">
              <p>{t('editAccount.required')}</p>
              <label className="label" htmlFor="name">
                {t("create-account.fields.name")}
              </label>
              <input
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name ? "input input--error" : "input"
                }
                placeholder={t("users.formEditPlaceholder.name")}
                disabled={isSubmitting}
              />
              <div
                className={
                  errors.name && touched.name
                    ? "c-error-text"
                    : "c-error-text c-error-text--hidden"
                }
              >
                <IconSelector
                  svg="ErrorWarningLine"
                  classname="icon--warning"
                />
                <span className="error-text">{errors.name}</span>
              </div>
            </div>

            {/* Celular */}
            <div className="c-input">
              <label className="label" htmlFor="cellPhone">
                {t("create-account.fields.cellPhone")}
              </label>
              <div className="phone-input-group">
                <Select
                  options={countryCodes}
                  value={
                    countryCodes.find(
                      (country) => country.code === values.country
                    ) ||
                    countryCodes.find((country) => country.code === "+55")
                  }
                  onChange={(selectedOption) =>
                    setFieldValue("country", selectedOption.code)
                  }
                  classNamePrefix="react-select"
                  isSearchable
                  getOptionLabel={(country) => (
                    <div className="select-option">
                      <img
                        src={country.flag}
                        className="country-flag"
                        alt="flag"
                      />
                      <span>{country.code}</span>
                    </div>
                  )}
                  components={{
                    SingleValue: ({ data }) => (
                      <div className="select-single-value">
                        <img
                          src={data.flag}
                          className="country-flag"
                          alt="flag"
                        />
                        <span>{data.code}</span>
                      </div>
                    ),
                    Option: ({ data, innerRef, innerProps }) => (
                      <div
                        ref={innerRef}
                        {...innerProps}
                        className="select-option"
                      >
                        <img
                          src={data.flag}
                          className="country-flag"
                          alt="flag"
                        />
                        <span>{data.code}</span>
                      </div>
                    ),
                  }}
                />
                <input
                  id="cellPhone"
                  name="cellPhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.cellPhone && touched.cellPhone
                      ? "input input--error"
                      : "input"
                  }
                  placeholder={t("users.formEditPlaceholder.cellphone")}
                  disabled={isSubmitting}
                />
              </div>
              <div
                className={
                  errors.cellPhone && touched.cellPhone
                    ? "c-error-text"
                    : "c-error-text c-error-text--hidden"
                }
              >
                <IconSelector
                  svg="ErrorWarningLine"
                  classname="icon--warning"
                />
                <span className="error-text">{errors.cellPhone}</span>
              </div>
            </div>

            {/* Submit */}
            <button
              className="edit-user-btn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <BtnLoadingText />
              ) : (
                t("users.editItemBtn")
              )}
            </button>
            <button
              className="edit-user-btn-prev"
              onClick={() => navigate(`/${host}/info-account`)}
            >

              {t("common.prev")}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default EditAccount;
