import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies, Cookies } from "react-cookie";
import {
  createUserAlertLocation,
  updateUserAlertsLocations,
} from "../../../utils/dataAPI";

function UserLocalForm({ FormData, buttonAddText }) {
  const [formData, setFormData] = useState(FormData ?? { nome: "", local: "" });
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const host = location.pathname.split("/")[1];
  const cookies = new Cookies();
  const [getCookies] = useCookies();

  const handleChange = async (e) => {
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });

    if (id === "local" && value.length > 2) {
      try {
        const token = localStorage.getItem("access_token");
        const url = `https://test.cittua.com/api/mobile/geocoder/autocomplete`;

        console.log("Sending request to:", url);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: value,
          }),
        });

        console.log("Response status:", response.status);

        if (response.ok) {
          const data = await response.json();
          console.log("API Response Data:", data);
          setSuggestions(data.data || []);
        } else {
          console.error(
            "Failed to fetch autocomplete suggestions:",
            response.status,
            response.statusText
          );
          setSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
        setSuggestions([]);
      }
    }
  };

  const validateNameLength = formData.nome.length > 2;
  const validateLocalLength = formData.local.length > 2;

  const validateForm = validateNameLength && validateLocalLength;

  const validateNameExistsAlready = (arrayOfLocals, formName) =>
    arrayOfLocals.some((local) => local.nome === formName);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm) {
      const oldUserLocals = getCookies.userLocals ?? [];

      if (FormData) {
        const updatingUserLocal = oldUserLocals.find(
          (local) => local.nome === FormData.nome
        );
        const index = oldUserLocals.indexOf(updatingUserLocal);
        oldUserLocals[index] = formData;
        const newUserLocals = [...oldUserLocals];
        cookies.set("userLocals", newUserLocals);
        updateUserAlertsLocations({
          address: formData.local,
          address_name: formData.nome,
        });
      } else {
        if (validateNameExistsAlready(oldUserLocals, formData.nome)) {
          return;
        }

        const data = {
          nome: formData.nome,
          local: formData.local,
        };
        const newUserLocals = [...oldUserLocals, data];
        cookies.set("userLocals", newUserLocals);

        localStorage.setItem("userLocals", JSON.stringify(newUserLocals));

        createUserAlertLocation({
          address: formData.local,
          address_name: formData.nome,
        });
      }
      navigate(`/${host}/info-locations`);
    } else {
      console.log("Preencha todos os campos corretamente");
    }
  };

  return (
    <form className="local-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="nome">{t("locations.formsCommon.fields.name")}</label>
        <input
          type="text"
          id="nome"
          name="nome"
          placeholder={t("locations.formsCommon.fieldsPlaceholder.name")}
          value={formData.nome}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="local">{t("locations.formsCommon.fields.location")}</label>
        <input
          type="text"
          id="local"
          name="local"
          placeholder={t("locations.formsCommon.fieldsPlaceholder.location")}
          value={formData.local}
          onChange={handleChange}
          autoComplete="off"
        />
        {suggestions.length > 0 && (
          <ul className="autocomplete-suggestions">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => {
                  setFormData({ ...formData, local: suggestion });
                  setSuggestions([]);
                }}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    setFormData({ ...formData, local: suggestion });
                    setSuggestions([]);
                  }
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="button-group">
        <button type="submit" className="primary-btn">
          {buttonAddText}
        </button>
        <Link
          type="button"
          to={`/${host}/info-locations`}
          className="secondary-btn"
        >
          {t("common.prev")}
        </Link>
      </div>
    </form>
  );
}

export default UserLocalForm;
