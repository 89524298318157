import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'

import { event } from '../../utils/googleAnalytics'

import PermissionsControl from '../PermissionsControl'

function NewTableItemBtn({ params }) {
  const { t } = useTranslation()
  const { tableType, configTable } = params
  const navigate = useNavigate()

  const onCreateNew = () => {
    //google analytics info//
    event(`open_create_${tableType}_item`, `open_create_table_item`, `open_create_${tableType}`)

    navigate('create')
  }

  return (
    <PermissionsControl
      role={configTable.permission}
      type='componentItem'
      action='create'
    >
      <Button
        onClick={onCreateNew}
        classes={{ root: 'btn-primary btn-primary--medium table-btn new-table-item-btn' }}
      >
        {t(`${params.tableType}.newItemBtn`)}
      </Button>
    </PermissionsControl>
  )
}

export default NewTableItemBtn
