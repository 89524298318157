import React, { useState } from "react";
import lixeiraIcon from "../assets/Lixeira.svg";
import megafoneIcon from "../assets/Megafone.svg";
import editIcon from "../assets/Edit.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { useCookies } from "react-cookie";
import { deleteUserAlertLocation } from "../../../utils/dataAPI";

function UserLocalCard({ local, onDelete }) {
  const { t } = useTranslation();
  const { nome, local: endereco } = local;
  const navigate = useNavigate();
  const location = useLocation();
  const host = location.pathname.split("/")[1];
  const [cookies, setCookie] = useCookies(["userLocals"]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDeleteLocation = async () => {
    try {
      const userLocals = cookies.userLocals || [];
      const newUserLocals = userLocals.filter((loc) => loc.nome !== nome);

      await deleteUserAlertLocation({ address: endereco, address_name: nome });

      setCookie("userLocals", newUserLocals);

      if (onDelete) {
        onDelete(local);
      }

      closeModal();
      navigate(`/${host}/info-locations`, { replace: true });
    } catch (error) {
      console.error("Erro ao deletar o local:", error);
    }
  };

  return (
    <div className="userLocal-card">
      <div className="userLocal-adress">
        <h4>{nome}</h4>
        <p>{endereco}</p>
      </div>
      <div className="userLocal-buttons">
        <Link to={`/${host}/edit-locations/${nome}`}>
          <img src={editIcon} alt="Edit" />
        </Link>
        <button onClick={openModal}>
          <img src={lixeiraIcon} alt="Lixeira" />
        </button>
      </div>

      {isModalOpen && (
        <Modal
          icon={megafoneIcon}
          text={t("locations.deleteModal.text")}
          title={t("locations.deleteModal.title")}
          btnActionText={t("locations.deleteModal.btnAction")}
          btnCloseText={t("locations.deleteModal.btnClose")}
          handleCloseModal={closeModal}
          handleButtonAction={handleDeleteLocation}
          iconCSSClass={"modal-icon"}
          titleCSSClass={"modal-title"}
          textCSSClass={"modal-description"}
        />
      )}
    </div>
  );
}

export default UserLocalCard;
