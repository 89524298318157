import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom"
import dayjs from "dayjs"

import { cpfValidation } from '../../../utils/inputValidations'
import CustomForm from '../../CustomForm'
import PermissionsControl from '../../PermissionsControl'
import PersonalData from './PersonalData'
import AccountData from './AccountData'

function UsersForm() {
	const { t } = useTranslation()
	const { itemId } = useParams()
	const [activeTab, setActiveTab] = useState(0)
	const categories = ['PersonalData', 'AccountData']

	function getInitialValues(item) {
		let initialValues = {
			first_name: item?.first_name || '',
			last_name: item?.last_name || '',
			cpf: item?.cpf || '',
			social_name: item?.social_name || '',
			birth_date: item?.birth_date || '',
			email: item?.email || '',
			profile_id: item?.profile_id || 'none',
		}

		return initialValues
	}

	function formValidation(values) {
		let errors = {}

		if (categories[activeTab] === 'PersonalData') {
			if (!values.first_name) {
				errors.first_name = t('common.inputErrors.fieldRequired')
			} else if (values.first_name.length < 3) {
				errors.first_name = t('common.inputErrors.minLengthError')
			}

			if (!values.last_name) {
				errors.last_name = t('common.inputErrors.fieldRequired')
			} else if (values.last_name.length < 3) {
				errors.last_name = t('common.inputErrors.minLengthError')
			}

			if (!values.cpf) {
				errors.cpf = t('common.inputErrors.fieldRequired')
			} else if (cpfValidation(values.cpf)) {
				errors.cpf = t('common.inputErrors.invalidCPF')
			}

			if (values.social_name && values.social_name.length < 3) {
				errors.social_name = t('common.inputErrors.minLengthError')
			}

			if (values.birth_date && !(dayjs(values.birth_date, 'DD/MM/YYYY', true)).isValid()) {
				errors.birth_date = t('common.inputErrors.invalidDate')
			}

			return errors
		}

		if (!values.email) {
			errors.email = t('common.inputErrors.fieldRequired')
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
			errors.email = t('common.inputErrors.invalidEmail')
		}

		if (values.profile_id === 'none') {
			errors.profile_id = t('common.inputErrors.fieldRequired')
		}

		return errors
	}

	function formatSubmitValues(values) {
		let submitValues = {
			form: values,
			requestUrl: itemId ? 'api/cittua/users/update' : 'api/cittua/users/create',
			method: 'post',
		}

		return submitValues
	}

	function FormTabs({ tab, props }) {
		const Tabs = {
			PersonalData,
			AccountData,
		}

		let Tab = Tabs[tab]

		return Tab !== undefined ?
			<Tab props={props} />
			: null
	}

	useEffect(() => {
		document.title = itemId ? t('users.pageTitle.edit') : t('users.pageTitle.create');
	})
	return (
		<div className='c-main-content c-main-content--form'>
			<PermissionsControl
				role='user'
				type='componentPage'
				action='update'
			>
				<CustomForm
					params={{
						formType: 'users',
						setActiveTab,
						categories,
						getInitialValues,
						formValidation,
						formatSubmitValues,
						requestData: {
							requestUrl: 'api/cittua/users/get',
							method: 'post',
						}
					}}
				>
					{(formProps) => (
						<FormTabs tab={categories[activeTab]} props={{ formProps, itemId }} />
					)}

				</CustomForm>
			</PermissionsControl>
		</div>
	)
}

export default UsersForm