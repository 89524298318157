import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";
import PermissionsControl from "../PermissionsControl";
import { alertTableConfig } from "../../data/AlertTableConfig/alertTableConfig";
import { useFormik } from "formik";
import { HeadeAlertTable } from "./header-alert-table/HeadeAlertTable";

function AlertTable() {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      dateStart: "",
      dateEnd: "",
    },
  });

  useEffect(() => {
    document.title = t("alerts.pageTitle.table");
  }, [t]);

  const buildRequestParams = (data, search, tableParams) => {
    const { dateStart, dateEnd } = formik.values;

    var requestURL = alertTableConfig.requestUrls.get;
    let options = {
      page: tableParams.page,
      perPage: tableParams.perPage,
      sortBy: tableParams.sortBy,
      order: tableParams.order,
      search: [],
    };

    if (
      dateStart &&
      dateStart !== "Invalid Date" &&
      dateEnd &&
      dateEnd !== "Invalid Date"
    ) {
      options.start_date = dateStart;
      options.end_date = dateEnd;
    }

    let requestParams = {
      options: options,
      requestUrl: requestURL,
      tableType: "alerts",
      ...(data.signal && { signal: data.signal }),
    };
    return requestParams;
  };

  return (
    <div className="c-main-content c-main-content--table">
      <PermissionsControl
        role={alertTableConfig.permission}
        type="componentPage"
        action="read"
      >
        <CustomTable
          params={{
            tableType: "alerts",
            configTable: alertTableConfig,
            HeaderRoot: HeadeAlertTable,
            buildRequestParams: buildRequestParams,
            redirectEditOnClick: false,
            formik: formik,
          }}
        />zzzzzz
      </PermissionsControl>
    </div>
  );
}

export default AlertTable;
