import { ReactComponent as CloseLine } from "../../assets/icons/close-line.svg";
import { ReactComponent as ErrorWarningLine } from "../../assets/icons/error-warning-line.svg";
import { ReactComponent as EyeCloseLine } from "../../assets/icons/eye-close-line.svg";
import { ReactComponent as EyeLine } from "../../assets/icons/eye-line.svg";
import { ReactComponent as QuestionLine } from "../../assets/icons/question-line.svg";
import { ReactComponent as Checkbox } from "../../assets/icons/checkbox-blank-line.svg";
import { ReactComponent as CheckedCheckbox } from "../../assets/icons/checkbox-line.svg";
import { ReactComponent as Home } from "../../assets/icons/home-icon.svg";
import { ReactComponent as Occurrences } from "../../assets/icons/occurrences.svg";
import { ReactComponent as Meteorology } from "../../assets/icons/meteorology.svg";
import { ReactComponent as Mobility } from "../../assets/icons/mobility.svg";
import { ReactComponent as History } from "../../assets/icons/history.svg";
import { ReactComponent as News } from "../../assets/icons/news.svg";
import { ReactComponent as Integration } from "../../assets/icons/integration.svg";
import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as Users2 } from "../../assets/icons/users2.svg";
import { ReactComponent as Profiles } from "../../assets/icons/profiles.svg";
import { ReactComponent as Profiles2 } from "../../assets/icons/profiles2.svg";
import { ReactComponent as OperationalControl } from "../../assets/icons/operational-control.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import { ReactComponent as FileText } from "../../assets/icons/file-text.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowLeft2 } from "../../assets/icons/arrow-left2.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as BackToFirst } from "../../assets/icons/back-to-first.svg";
import { ReactComponent as ForwardToLast } from "../../assets/icons/forward-to-last.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle.svg";
import { ReactComponent as CloseCircle } from "../../assets/icons/close-circle.svg";
import { ReactComponent as CheckboxIndeterminate } from "../../assets/icons/checkbox-indeterminate.svg";
import { ReactComponent as Gift } from "../../assets/icons/gift.svg";
import { ReactComponent as Card } from "../../assets/icons/card.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as LocationPin } from "../../assets/icons/location-pin.svg";
import { ReactComponent as LocationPin2 } from "../../assets/icons/location-pin2.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { ReactComponent as Sunny } from "../../assets/icons/sol.svg";
import { ReactComponent as Cloudy } from "../../assets/icons/nublado.svg";
import { ReactComponent as Rain } from "../../assets/icons/chuva.svg";
import { ReactComponent as Storm } from "../../assets/icons/tempestade.svg";
import { ReactComponent as CleanSky } from "../../assets/icons/lua.svg";
import { ReactComponent as Windstorm } from "../../assets/icons/wind.svg";
import { ReactComponent as Flooding } from "../../assets/icons/flooding.svg";
import { ReactComponent as Landslide } from "../../assets/icons/landslide.svg";
import { ReactComponent as WarningVeryHigh } from "../../assets/icons/warning-very-high-risk.svg";
import { ReactComponent as WarningHigh } from "../../assets/icons/warning-high-risk.svg";
import { ReactComponent as WarningMedium } from "../../assets/icons/warning-medium-risk.svg";
import { ReactComponent as WarningLow } from "../../assets/icons/warning-low-risk.svg";
import { ReactComponent as CheckV } from "../../assets/icons/Check-V.svg";
import { ReactComponent as Fechar } from "../../assets/icons/Fechar.svg";
import { ReactComponent as ExcluirIcon } from "../../assets/icons/Excluir.svg";
import { ReactComponent as Notification } from "../../assets/icons/aviso.svg";
import { ReactComponent as NoResultFound } from "../../assets/icons/no-result-found.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/DownArrow.svg";
import { ReactComponent as ContestPaste } from "../../assets/icons/content-paste.svg";
import { ReactComponent as Cold } from "../../assets/icons/cold.svg";
import { ReactComponent as Cyclone } from "../../assets/icons/cyclone.svg";
import { ReactComponent as Dry } from "../../assets/icons/low-humidity.svg";
import { ReactComponent as Earthquake } from "../../assets/icons/earthquake.svg";
import { ReactComponent as Texturebox } from "../../assets/icons/texture-box.svg";
import { ReactComponent as Threedots } from "../../assets/icons/three-dots.svg";
import { ReactComponent as Mapcalendar } from "../../assets/icons/map-calendar.svg";
import { ReactComponent as CalendarPurple } from "../../assets/icons/calendar-purple.svg";
import { ReactComponent as Maplegend } from "../../assets/icons/map-legend.svg";
import { ReactComponent as NoWind } from "../../assets/icons/no-wind.svg";
import { ReactComponent as VeryWeak } from "../../assets/icons/very-weak.svg";
import { ReactComponent as Weak } from "../../assets/icons/weak.svg";
import { ReactComponent as Moderate } from "../../assets/icons/moderate.svg";
import { ReactComponent as VeryStrong } from "../../assets/icons/very-strong.svg";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as SuccessExport } from "../../assets/icons/success-export.svg";
import { ReactComponent as SuccessCreate } from "../../assets/icons/success-create.svg";
import { ReactComponent as ErrorExport } from "../../assets/icons/error-export.svg";
import { ReactComponent as RainReportNoRain } from "../../assets/icons/rain-no-rain.svg";
import { ReactComponent as RainReportRain } from "../../assets/icons/rain-rain.svg";
import { ReactComponent as RainReportNoData } from "../../assets/icons/rain-no-data.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as SettingsUser } from "../../assets/icons/settings-user.svg";
import { ReactComponent as SettingsLocations } from "../../assets/icons/settings-locations.svg";
import { ReactComponent as SettingsTeam } from "../../assets/icons/settings-team.svg";
import { ReactComponent as SettingsOrg } from "../../assets/icons/settings-org.svg";
import { ReactComponent as SettingsTools } from "../../assets/icons/settings-tools.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as BlinkEyes } from "../../assets/icons/blink-eyes.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Congratz } from "../../assets/icons/feat.svg";
import { ReactComponent as HappyFace } from "../../assets/icons/8D.svg";
import { ReactComponent as HeartEyes } from "../../assets/icons/heart-eyes.svg";
import { ReactComponent as Person } from "../../assets/icons/Profile.svg";
import { ReactComponent as Exit } from "../../assets/icons/Exit.svg";

function IconSelector({ svg, size, classname }) {
  const Icons = {
    CloseLine,
    ErrorWarningLine,
    EyeCloseLine,
    EyeLine,
    QuestionLine,
    Checkbox,
    CheckedCheckbox,
    Home,
    Occurrences,
    Meteorology,
    Mobility,
    History,
    News,
    Integration,
    Users,
    Users2,
    Profiles,
    Profiles2,
    OperationalControl,
    ChevronRight,
    ChevronLeft,
    ChevronDown,
    ChevronUp,
    FileText,
    Warning,
    ArrowDown,
    ArrowLeft,
    ArrowLeft2,
    ArrowRight,
    BackToFirst,
    ForwardToLast,
    Search,
    CheckCircle,
    CloseCircle,
    CheckboxIndeterminate,
    Gift,
    Card,
    Mail,
    LocationPin,
    LocationPin2,
    Lock,
    Sunny,
    Cloudy,
    Rain,
    Storm,
    CleanSky,
    Windstorm,
    Flooding,
    Landslide,
    WarningVeryHigh,
    WarningHigh,
    WarningMedium,
    WarningLow,
    CheckV,
    Fechar,
    ExcluirIcon,
    Notification,
    NoResultFound,
    Edit,
    DownArrow,
    ContestPaste,
    Cold,
    Cyclone,
    Dry,
    Earthquake,
    Texturebox,
    Threedots,
    Mapcalendar,
    Maplegend,
    NoWind,
    VeryWeak,
    Weak,
    Moderate,
    VeryStrong,
    Report,
    SuccessExport,
    SuccessCreate,
    ErrorExport,
    RainReportNoRain,
    RainReportRain,
    RainReportNoData,
    CalendarPurple,
    Settings,
    SettingsUser,
    SettingsLocations,
    SettingsTeam,
    SettingsOrg,
    SettingsTools,
    Menu,
    BlinkEyes,
    Plus,
    Congratz,
    HappyFace,
    HeartEyes,
    Person,
    Exit
  };

  let Icon = Icons[svg];

  return Icon ? (
    <Icon style={{ width: size, height: size }} className={classname} />
  ) : null;
}

export default IconSelector;
