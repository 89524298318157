import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../assets/icons/edit.svg";


export const AlertEditButton = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditBtn = () => {
    navigate(`edit/${id}`);
  };

  return (
    <Button className="btn-table-edit" onClick={handleEditBtn}>
      <img src={EditIcon} alt="Editar" className="icon--edit-btn" />
      <p className="edit-btn-text">{t("common.table.action-btn.edit")}</p>
    </Button>
  );
};
